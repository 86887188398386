@font-face {
  font-family: Tintoretto;
  src: url("../src/fonts/Tintoretto.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Playfair Display",
    "Merriweather", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ff48350d;

  // custom Scrollar
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #eee;
  }
}

:root {
  --logo-font: Tintoretto, "sans-serif";
}
* {
  box-sizing: border-box;
}

@media only screen and (max-width: 1345px) {
  html {
    zoom: 0.9;
  }
}

html,
body,
#root {
  height: 100%;
}
