.under-maintenance {
  height: 100vh;
  width: 100vw;
  background-image: url(../../imgs/under-man.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  &-heading {
    text-align: center;
    font-weight: bolder;
    font-size: 50px;
    font-style: italic;
    padding-top: 40vh;
    color: #2e2e2e;
    font-family: "Playfair Display", "Merriweather";
  }
}
