.footer {
  height: 110px;
  width: 100%;
  background-color: #124983;
  border-top: 3px solid #124983;
  position: fixed;
  bottom: 0;
  color: #ff4835;
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  justify-content: space-between;
  z-index: 100000;
  /* waves */
  .ocean {
    height: 60px; /* change the height of the waves here */
    width: 100%;
    position: absolute;
    top: -55px;
    left: 0;
    right: 0;
    overflow-x: hidden;
  }

  .wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 30s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.6;
  }

  .wave:nth-of-type(2) {
    bottom: 0;
    animation: wave 34s linear reverse infinite;
    opacity: 0.4;
  }

  .wave:nth-of-type(3) {
    bottom: 0;
    animation: wave 40s -1s linear infinite;
    opacity: 0.3;
  }

  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-12%);
    }
    50% {
      transform: translateX(-25%);
    }
    75% {
      transform: translateX(-30%);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .site-name {
      font-family: var(--logo-font);
      font-style: italic;
      font-size: 30px;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      cursor: pointer;
    }
    .site-name:hover {
      color: #d71400;
      box-shadow: 0 0.1px #d71400;
    }
    .citation {
      font-style: italic;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    .other {
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      width: 200px;
    }
  }
}
