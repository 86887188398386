.header {
  position: fixed;
  top: 0;
  height: 110px;
  width: 100%;
  background-color: #124983;
  border-top: 3px solid #124983;
  padding: 25px 50px;
  color: #ff4835;
  display: flex;
  justify-content: space-between;
  z-index: 100000;

  .logo {
    font-family: var(--logo-font);
    font-style: italic;
    font-size: 30px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .logo:hover {
    box-shadow: 0 0.1px #d71400;
    color: #d71400;
  }

  .header-routes {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;

    div {
      font-size: 20px;
      font-style: italic;
      cursor: pointer;
      padding: 10px 30px;
    }
    div:nth-child(2n-1) {
      border-right: 1px solid red;
    }
    div:nth-child(3n-1) {
      border-right: 1px solid red;
    }
    div:hover {
      box-shadow: 0 0.1px #d71400;
      // color: #d71400;

      // background-color: red;

      /* Create the gradient. */
      background-image: linear-gradient(
        15deg,
        rgba(18, 73, 131, 1) 0%,
        rgba(255, 72, 53, 1) 53%
      );

      /* Set the background size and repeat properties. */

      /* Use the text as a mask for the background. */
      /* This will show the gradient as a text color rather than element bg. */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }
}
